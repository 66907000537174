const n26 = ['AFM NL', 'AMF', 'Agence Nationale de la Sécurité des Systèmes d\'Information (ANSSI)', 'Agencia Española de Protección de Datos',
  'Associazione Bancaria Italiana', 'Autoriteit Persoonsgegevens', 'Autorité de contrôle prudentiel et de résolution (ACPR)', 'Autorité de la concurrence',
  'Banca d\'Italia', 'Banco de España', 'Banque de France', 'Boletín Oficial del Estado (BOE)', 'CNIL', 'CNMV', 'CONSOB', 'Comité Français d\'Organisation et de Normalisation Bancaires (CFONB)',
  'Direction Générale de la Concurrence, de la Consommation et de la Répression des Fraudes (DGCCRF)', 'FMA Austria', 'Fédération Bancaire Française',
  'Garante', 'Gazzetta Ufficiale della Repubblica Italiana', 'IVASS', 'Legifrance', 'Ministère de l\'Économie, des Finances et de la Relance',
  'Oesterreichische Nationalbank', 'Tracfin']

export default {
  accounting: ['AFME', 'BIS', 'EBA', 'ECB', 'EFRAG', 'FRC', 'ICAEW', 'IFRS', 'UK Finance'],
  clients: n26,
  commodities: [
    'Autoriteit Consument & Markt',
    'Commission for Regulation of Utilities',
    'CRE',
    'CREG',
    'Elia',
    'National Grid ESO',
    'OFGEM',
    'RTE France',
    'Tennet'
  ],
  dataprivacy: ['Agencia Española de Protección de Datos', 'Autoriteit Persoonsgegevens', 'CNIL',
    'Data Protection Commission Ireland', 'Datainspektionen',
    'Datatilsynet DK', 'Datatilsynet Norway', 'Garante', 'ICO', 'LGPD', 'Office of the Australian Information Commissioner',
    'Office of the Privacy Commissioner New Zealand', 'Office of the Information and Privacy Commissioner for British Columbia',
    'Office of the Privacy Commissioner of Canada', 'Personal Data Protection Commission Singapore', 'PPC Japan',
    'State Data Protection Inspectorate'],
  energy: [
    'CMA',
    'Department for Energy Security and Net Zero',
    'National Grid ESO',
    'OFGEM',
    'OFWAT'
  ],
  eucore: ['EBA', 'EC', 'ECB', 'ECB Banking Supervision', 'ECON', 'EIOPA', 'ESAS Joint Committee', 'ESMA', 'ESRB', 'KPMG Ireland'],
  intcore: ['BIS', 'FSB', 'IAIS', 'IOSCO', 'ISDA'],
  level1: ['AMLA', 'BaFin', 'BIS', 'BoE', 'Bundesbank', 'EBA', 'EC', 'ECB', 'ECB Banking Supervision', 'ECON', 'EDPB', 'EFRAG', 'EIOPA',
    'EU Journal', 'ESAS Joint Committee', 'ESMA', 'ESRB', 'European Council', 'FCA',
    'Financial Ombudsman Service', 'FSB', 'HM Treasury', 'IAIS', 'IFRS', 'IOSCO', 'KPMG UK', 'KPMG Ireland', 'Pay UK', 'PRA', 'PSR', 'Pensions Regulator',
    'Transition Plan Taskforce', 'Treasury Select Committee', 'Treasury Sub-Committee on Financial Services Regulations'],
  level2: ['ABI', 'ACPR', 'AFM NL', 'AFME', 'AIC', 'AMF', 'ARRC', 'BaFin', 'Building Societies Association', 'CBI',
    'Chartered Banker Institute', 'CNMV', 'CSSF', 'DNB', 'EBF', 'ECSDA', 'EFAMA', 'European Capital Markets Institute',
    'European Mortgage Federation', 'European Payments Council', 'FIA', 'Finance Watch', 'FMLC', 'FMSB', 'FS-ISAC', 'GFMA',
    'GFXC', 'IA', 'ICMA', 'IIF', 'Institute of Economic Affairs', 'Insurance Europe', 'ISDA', 'Lending Standards Board',
    'Pensions Advisory Service', 'PIMFA', 'The City UK', 'TISA', 'UK Finance'],
  lifescience: [
    'Association of the British Pharmaceutical Industry',
    'Läkemedelsverket',
    'British Standards Institution',
    'British Standards Institution',
    'Health Products Regulatory Authority',
    'Health Canada',
    'Paul Ehrlich Institute',
    'Agencia Española de Medicamentos y Productos Sanitarios',
    'Agenzia Italiana del Farmaco',
    'Food and Drug Administration Thailand',
    'Ministry of Food and Drug Safety',
    'Therapeutic Goods Administration',
    'Medsafe',
    'ICH',
    'Heads of Medicines Agencies',
    'Health Sciences Authority',
    'Badan Pengawas Obat dan Makanan',
    'Drug Administration of Vietnam',
    'Taiwan Centre for Drug Evaluation',
    'Taiwan Food and Drug Administration',
    'Japan Ministry of Health Labour and Welfare',
    'National Pharmaceutical Regulatory Agency',
    'Medical Device Authority',
    'Agência Nacional de Vigilância Sanitária - Anvisa',
    'Brunei Ministry of Health',
    'Philippines Food and Drug Administration',
    'Swissmedic',
    'National Medical Products Administration',
    'WHO',
    'Association of British HealthTech Industries',
    'British In Vitro Diagnostic Association',
    'MedTech Europe',
    'OECD',
    'NC3Rs'
  ],
  ukcore: ['BoE', 'FCA', 'Financial Ombudsman Service', 'HM Treasury', 'KPMG UK', 'Pay UK', 'PRA', 'PSR', 'Pensions Regulator', 'Treasury Select Committee'],
  uscore: ['CFTC', 'DFS', 'DOJ', 'FINRA', 'FRB', 'OCC', 'SEC']
}
